
import Vue from "vue";
import { permissions } from "@/mixins";
import { Actions } from "@/store/models";
import { companies, CompanyStatus, ICompany } from "@/api";
import { getDate, isExpiredCard } from "@/utils";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

export default Vue.extend({
  components: { ConfirmationDialog },
  name: "GssiAdminAccounts",
  mixins: [permissions],
  data() {
    return {
      searchQuery: "",
      headers: [
        { text: "NAMES", value: "name" },
        { text: "ACCOUNT #", value: "account_number" },
        { text: "OWNER ID", value: "cao.user_name" },
        { text: "TIER TYPE", value: "license.license_name" },
        { text: "ACCOUNT OPEN DATE", value: "created_datetime" },
        { text: "RENEWAL DATE", value: "renewal_date" },
        { text: "CC EXPIRY DATE", value: "card_info.expiry" },
        { text: "GSSI ACCOUNT", value: "gssi_company" },
        { text: "STATUS", value: "status" },
      ],
      items: [] as ICompany[],
      companyStatus: [
        { id: 1, val: "ACTIVE" },
        { id: 2, val: "SUSPENDED" },
        { id: 3, val: "EXPIRED" },
      ],
      statusUpdateDialog: false,
      confirmationText: "",
      companyToUpdate: {} as ICompany,
    };
  },

  computed: {
    filteredItems: function (): ICompany[] {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  created() {
    this.getAccounts();
  },
  methods: {
    async getAccounts() {
      try {
        this.items = (await companies.getAll()).data;
        this.items.forEach((item: ICompany) => {
          item.created_datetime = getDate(item.created_datetime);
          item.renewal_date = getDate(item.renewal_date!);
          item.license!.license_name =
            item.license!.license_name.charAt(0).toUpperCase() +
            item.license!.license_name.slice(1);
          //if (item.card_info?.expiry) {
          //item.card_info.expiry = getDate(item.card_info.expiry);
          //}
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async goToDashboard(item: ICompany) {
      this.$router.push({ path: `${item.id}/dashboard` });
    },
    confirmStatusUpdate(item: ICompany) {
      this.companyToUpdate = item;
      if (item.status === CompanyStatus.ACTIVE) {
        this.updateStatus();
      } else {
        if (item.status === CompanyStatus.SUSPENDED) {
          this.confirmationText =
            "All Shared Reports will be deleted, Do you want to change to Suspended State?";
        } else if (item.status === CompanyStatus.EXPIRED) {
          this.confirmationText =
            "All Projects and Users will be deleted, Do you want to change to Expired State?";
        }
        this.statusUpdateDialog = true;
      }
    },
    async updateStatus() {
      try {
        await companies.updateStatus(
          this.companyToUpdate.id,
          CompanyStatus[this.companyToUpdate.status] as any
        );
        this.getAccounts();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    getStatusColor(item: any) {
      switch (item.id) {
        case 1:
          return "#78d149";
        case 2:
          return "#e96f45";
        case 3:
          return "#f3ba58";
      }
    },
    async updateGssiCompany(item: ICompany) {
      try {
        await companies.updateGssiCompany(
          item.id,
          item.gssi_company ? "1" : ("0" as any)
        );
        this.getAccounts();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    getExpiryDateClass(item: any) {
      return isExpiredCard(item.card_info.expiry) ? "expired-card" : "";
    },
  },
});
